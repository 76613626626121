import React, { useEffect, useState } from 'react';
import { fetchAllOrders } from './Hygraph'
import Logo from './zipbox-logo .png'
import './App.css'

const LoginForm = ({ onSubmit, error }) => (
  <div className="main-container">
    <div>
    <img src={Logo} alt="zipbox" width={200} />
    </div>
    <div className='form-container'>
    <h1>Please enter your password to access the dashboard</h1>
    <form onSubmit={onSubmit}>
      <input
        type="password"
        placeholder="Enter password"
        className="input-container"
        autoComplete="off"
      />
      <button className="form-button" type="submit">
        Submit
      </button>
    </form>
    {error && <p className="error-text">{error}</p>}
    </div>
  </div>
);

const OrdersTable = ({ orders }) => {
  const formatStatus = (status) =>
    status
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, (str) => str.toUpperCase());

  return (
    <table className='table-container'>
      <thead>
        <tr>
          <th>Order ID</th>
          <th>Tracking Number</th>
          <th>Customer Name</th>
          <th>Customer Email</th>
          <th>Customer Address</th>
          <th>Order Status</th>
        </tr>
      </thead>
      <tbody>
        {orders.map((order) => (
          <tr key={order.trackingNumber}>
            <td>{order.orderId || 'N/A'}</td>
            <td>{order.trackingNumber || 'N/A'}</td>
            <td>{order.customerName || 'N/A'}</td>
            <td>{order.customerEmail || 'N/A'}</td>
            <td>{order.customerAddress || 'N/A'}</td>
            <td>
              {order.trackingPoint?.map((point, index) => (
                <span key={`${order.trackingNumber}-${index}`}>
                  {formatStatus(point.orderStatus)}
                </span>
              )) || 'N/A'}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const Dashboard = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const correctPassword = 'ODDBUNCH9867';

  useEffect(() => {
    const savedAuthStatus = localStorage.getItem('isAuthenticated');
    if (savedAuthStatus === 'true') {
      setIsAuthenticated(true);
    }
    setLoading(false);
  }, []);

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    const password = e.target[0].value;
    if (password === correctPassword) {
      setIsAuthenticated(true);
      localStorage.setItem('isAuthenticated', 'true');
      setError(null);
    } else {
      setError('Incorrect password');
    }
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('isAuthenticated');
  };

  useEffect(() => {
    if (!isAuthenticated) return;

    const getOrders = async () => {
      setLoading(true);
      try {
        const data = await fetchAllOrders();
        setOrders(data);
      } catch (err) {
        setError('Error fetching orders');
      } finally {
        setLoading(false);
      }
    };

    getOrders();
  }, [isAuthenticated]);

  if (!isAuthenticated) {
    return <LoginForm onSubmit={handlePasswordSubmit} error={error} />;
  }

  return (
    <div className="container">
      <header className='container-header'>
        <div>
        <img src={Logo} alt="zipbox" width={200} />
       </div>
       <div>
      <button onClick={handleLogout} className="logout-button">Logout</button>
      </div> 
      </header>
      <h2> ODD BUNCH</h2>
      <h3> Orders Shipped - December 12 2024</h3>
      {loading ? <p>Loading...</p> : <OrdersTable orders={orders} />}
      {error && <p>{error}</p>}
     </div>
  );
};

export default Dashboard;
