import { gql, GraphQLClient } from 'graphql-request';

const HYGRAPH_ENDPOINT = 'https://us-west-2.cdn.hygraph.com/content/cm361utbu00is07w8qsxq2ksk/master';
const HYGRAPH_API_TOKEN = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE3MzExMjY3MjksImF1ZCI6WyJodHRwczovL2FwaS11cy13ZXN0LTIuaHlncmFwaC5jb20vdjIvY20zNjF1dGJ1MDBpczA3dzhxc3hxMmtzay9tYXN0ZXIiLCJtYW5hZ2VtZW50LW5leHQuZ3JhcGhjbXMuY29tIl0sImlzcyI6Imh0dHBzOi8vbWFuYWdlbWVudC11cy13ZXN0LTIuaHlncmFwaC5jb20vIiwic3ViIjoiOGM5NzUwYzMtY2ZmMi00YjUwLWFmOTAtZDZkNWMwN2RjZDdhIiwianRpIjoiY20zOW81b3lpMGJ5dzA3bjc5eTFzNWx4MSJ9.BVhSyXRNGLja6TT0qZ-mBUjAjRsiwdTyiaBGJ7s-aICFdc8sElMnt_1s3ebOch7wixdjdqDOViT5ZCMQXth_mFf8CjoZYJADDISeufGvZdXeG4CmUoRaiEyIcxP9aBzQUs6Fd6FM1J0WTBI9BQYWOUUD_nU7JQsbDfBLuwrRPB3Cimuc4CHsRipQzqhE6cQ8WKDsQh3T1l3sGfxEWjopDUQRVuVEiEo4gHjZUFgOA_URqE5YpXbmuT0HAykBxRDdP-CPyR6bz4JFGba39mEcfhN5Xg1bNMOoZG3DMBvASyiWw0nL18xAxJA77KYNrJCKNt-ng0txRKzwYtv7-rOOXahcj9uTJjIELSDLUv4T2tKdTuDwOt1h66G4kPkZv3LTHVUJdHOdVldLZLvfNnLmWUY2XCJOt2mIjyzfkgRHWsYk6yUQo8T0mRInSgZcIPMyF7hTQ_yvuaLRxGZ8SnoU33zZIBKbjXynQcFFn5BDEZThp4plbnfQkioTUcGbQ1gLRNYqUL9rKkB6JwWRoqyxZ_KYkCUqESnRGgdBpCtKwTALDRXGmFcqANDJX0b2tUIAx5j1P6BP_tEojHMy6XctuKDO1fZGJvqzivxTiMMoU0CjPFsslhzp1vKUH2sv0wMTjGq_5VsGN-u8RnT1iHWovWzDLOmUo2NPtDnjX50JWGY'; // Optional: use if your API requires authentication

const client = new GraphQLClient(HYGRAPH_ENDPOINT, {
  headers: {
    Authorization: `Bearer ${HYGRAPH_API_TOKEN}`,
  },
});

// Updated GraphQL query with pagination
const GET_ALL_ORDERS_QUERY = gql`
  query GetAllOrders($first: Int, $skip: Int) {
    timetableS(first: $first, skip: $skip) {
      trackingNumber
      customerEmail
      customerName
      customerAddress
      orderId
      trackingPoint {
        orderStatus
      }
    }
  }
`;

export const fetchAllOrders = async () => {
  try {
    const first = 10; // Number of orders to fetch per batch
    let skip = 0; // Start with 0 items skipped
    let allOrders = [];
    let hasMore = true;

    while (hasMore) {
      const data = await client.request(GET_ALL_ORDERS_QUERY, { first, skip });
      const fetchedOrders = data.timetableS;
      allOrders = [...allOrders, ...fetchedOrders];

      // Check if there are more orders to fetch
      if (fetchedOrders.length < first) {
        hasMore = false;
      } else {
        skip += first;
      }
    }

    return allOrders;
  } catch (error) {
    console.error("Error fetching all orders:", error);
    return [];
  }
};
